<template>
  <div>
    <v-col cols="12">
      <!-- <v-card :loading="$store.state.loading">
        <v-card-title class="font-weight-bold"> معلومات المطعم </v-card-title>
        <v-divider /> -->
      <v-form
        @submit.prevent="saveInfo"
        :disabled="$store.state.loading"
        :v-model="valid"
      >
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-card :loading="$store.state.loading">
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-card flat>
                        <v-card-title>
                          المعلومات الاساسية
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-container fluid>
                          <v-col cols="12">
                            <p class="font-weight-bold mb-2">اسم المطعم:</p>
                            <v-text-field
                              v-model="form.title"
                              :rules="[$rules.required()]"
                              outlined
                              required
                              hide-details="auto"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <p class="font-weight-bold mb-2">
                              وصف بسيط عن المطعم:
                            </p>
                            <v-textarea
                              v-model="form.description"
                              outlined
                              required
                              hide-details="auto"
                            >
                            </v-textarea>
                          </v-col>
                          <v-col cols="12">
                            <p class="font-weight-bold mb-2">وصف طويل:</p>
                            <v-md-editor
                              v-model="form.content"
                              :text="form.content"
                              height="400px"
                            ></v-md-editor>
                          </v-col>
                        </v-container>
                      </v-card>
                    </v-col>
                    <v-col cols="6">
                      <v-card flat>
                        <v-card-title>
                          المعلومات المالية
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-container fluid>
                          <v-col cols="12">
                            <p class="font-weight-bold mb-2">اجور التوصيل:</p>
                            <v-text-field
                              v-model="form.deliveryFee"
                              outlined
                              required
                              hide-details="auto"
                              type="number"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <p class="font-weight-bold mb-2">عملة الموقع :</p>
                            <v-text-field
                              v-model="form.currency"
                              outlined
                              required
                              placeholder="مثل: د.ع"
                              hide-details="auto"
                            >
                            </v-text-field>
                          </v-col>
                        </v-container>
                      </v-card>
                      <v-card flat>
                        <v-card-title>
                          التواصل واوقات العمل
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12" md="6">
                              <p class="font-weight-bold mb-2">وقت الافتتاح:</p>
                              <v-menu
                                ref="menuFrom"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="from"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="from"
                                    label="من"
                                    prepend-icon="mdi-clock-time-four-outline"
                                    readonly
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                  v-model="from"
                                  full-width
                                  @click:minute="$refs.menuFrom.save(from)"
                                >
                                </v-time-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="12" md="6">
                              <p class="font-weight-bold mb-2">
                                اوقات الاغلاق:
                              </p>
                              <v-menu
                                ref="menuTo"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="to"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="to"
                                    label="الى"
                                    prepend-icon="mdi-clock-time-four-outline"
                                    readonly
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                  v-model="to"
                                  full-width
                                  @click:minute="$refs.menuTo.save(to)"
                                >
                                </v-time-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="12">
                              <p class="font-weight-bold mb-2">رقم الهاتف:</p>
                              <v-text-field
                                v-model="form.phone"
                                outlined
                                required
                                hide-details="auto"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <p class="font-weight-bold mb-2">
                                رقم الهاتف الثاني:
                              </p>
                              <v-text-field
                                v-model="form.phone2"
                                outlined
                                required
                                hide-details="auto"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <p class="font-weight-bold mb-2">العنوان :</p>
                              <v-text-field
                                v-model="form.address"
                                outlined
                                required
                                hide-details="auto"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <p class="font-weight-bold mb-2">
                                عنوان قائمة المفضلة:
                              </p>
                              <v-text-field
                                v-model="form.favorite_menu"
                                outlined
                                required
                                hide-details="auto"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-card-title>
              واجهة المطعم والنمط
            </v-card-title>
            <v-row>
              <v-col cols="6">
                <v-card outlined>
                  <v-card-title>
                    <h3>صورة الخلفية</h3>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="handleFileImport('background')"
                      color="primary"
                      class="mt-2"
                      large
                    >
                      <v-icon>mdi-pencil</v-icon>
                      تعديل الصورة
                    </v-btn>
                    <input
                      :ref="`background`"
                      accept="image/*"
                      type="file"
                      class="d-none"
                      @change="
                        (e) => {
                          onFileChanged(e);
                        }
                      "
                    />
                  </v-card-title>
                  <v-container>
                    <v-img
                      v-if="form.background != null"
                      contain
                      :src="
                        backgroundString == null
                          ? $service.url + form.background.url
                          : backgroundString
                      "
                      max-height="150"
                    ></v-img>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card outlined class="mst-10">
                  <v-card-title>
                    <h3>صورة شعار المطعم</h3>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="handleFileImport('logo')"
                      color="primary"
                      class="mt-2"
                      large
                    >
                      <v-icon>mdi-pencil</v-icon>
                      تعديل الصورة
                    </v-btn>
                    <input
                      :ref="`logo`"
                      accept="image/*"
                      type="file"
                      class="d-none"
                      @change="
                        (e) => {
                          onFileChanged(e);
                        }
                      "
                    />
                  </v-card-title>
                  <v-container>
                    <img
                      style="border: 1px solid #ddd"
                      height="100"
                      :src="
                        logoString == null
                          ? $service.url + form.logo.url
                          : logoString
                      "
                    />
                  </v-container>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card>
                  <v-card-title> نمط المنيو </v-card-title>
                  <v-container>
                    <v-item-group>
                      <v-container>
                        <v-row>
                          <v-col
                            v-for="style in styles"
                            :key="style.id"
                            cols="12"
                            md="6"
                          >
                            <v-item>
                              <v-card
                                flat
                                class="
                                      d-flex
                                      flex-column
                                      align-center
                                      justify-center
                                    "
                              >
                                <!-- <v-img>\/ -->
                                <template v-if="style.photo != null"
                                  ><img
                                    :src="$service.url + style.photo.url"
                                    height="200"
                                /></template>

                                <!-- </v-img>\/ -->
                                <h3 class="text-center">
                                  {{ style.type }}
                                </h3>
                                <p class="text-center">
                                  {{ style.description }}
                                </p>
                                <v-btn
                                  @click="form.menuTheme = style.type"
                                  :color="
                                    style.type == form.menuTheme
                                      ? 'success'
                                      : 'primary'
                                  "
                                  :text="style.type == form.menuTheme"
                                >
                                  <v-icon>
                                    {{
                                      style.type == form.menuTheme
                                        ? "mdi-check"
                                        : "mdi-pencil"
                                    }}
                                  </v-icon>
                                  {{
                                    style.type == form.menuTheme
                                      ? "محدد"
                                      : "تعديل"
                                  }}
                                </v-btn>
                              </v-card>
                            </v-item>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-item-group>
                  </v-container>
                  <v-divider></v-divider>
                  <v-container>
                    <v-col cols="12">
                      <v-card flat>
                        <v-card-title>
                          <h3>الوان المينو</h3>
                        </v-card-title>
                        <v-container>
                          <v-radio-group v-model="form.theme">
                            <v-radio label="داكن" value="dark"></v-radio>
                            <v-radio label="فاتح" value="light"></v-radio>
                            <v-radio label="مخصص" value="custom"></v-radio>
                          </v-radio-group>
                          <v-row v-if="form.theme == 'custom'">
                            <v-col cols="6">
                              <h5>لون الخلفية</h5>
                              <v-color-picker
                                mode="hexa"
                                hide-mode-switch
                                v-model="form.backgroundColor"
                              />
                            </v-col>
                            <v-col cols="6">
                              <h5>لون الكارد</h5>
                              <v-color-picker
                                mode="hexa"
                                hide-mode-switch
                                v-model="form.cardColor"
                              />
                            </v-col>
                            <v-col cols="4">
                              <h5>باقي الوان الموقع</h5>
                              <v-radio-group row v-model="form.customTheme">
                                <v-radio label="داكن" value="dark"></v-radio>
                                <v-radio label="فاتح" value="light"></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                          <v-divider></v-divider>
                          <v-row class="mt-3">
                            <v-col cols="6">
                              <h5 class="mb-2">لون الاساسي</h5>
                              <v-color-picker
                                mode="hexa"
                                hide-mode-switch
                                v-model="form.primaryColor"
                              />
                            </v-col>
                            <v-col cols="6">
                              <h5 class="mb-2">لون الثانوي</h5>
                              <v-color-picker
                                mode="hexa"
                                hide-mode-switch
                                v-model="form.secondaryColor"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="12">
                <div class="d-flex justify-end">
                  <v-btn
                    type="submit"
                    x-large
                    fixed
                    bottom
                    left
                    style="z-index: 6"
                    color="primary"
                    :loading="$store.state.loading"
                    :disabled="(!valid || $store.state.loading)"
                  >
                    <v-icon>mdi-pencil-box</v-icon>
                    حفظ الاعدادات المعدلة
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-row>
        </v-container>
      </v-form>
      <!-- </v-card> -->
    </v-col>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      valid: true,
      logoFile: null,
      logoString: null,
      backgroundFile: null,
      backgroundString: null,
      toUpload: "",
      orderProduct: {},
      to: "",
      from: "",
      form: {
        title: "",
        description: "",
        content: "",
        logo: {
          url: "",
        },
        background: "",
        primaryColor: "",
        secondaryColor: "",
        currency: "",
        blocked: true,
        theme: "light",
        backgroundColor: "",
        customTheme: "light",
        cardColor: "",
        deliveryFee: 0,
        sync_url: "",
        menuTheme: "regular",
        open: {
          from: "",
          to: "",
        },
        address: "",
        phone: "",
      },
      styles: [],
      themes: ["regular", "list"],
    };
  },
  created() {
    this.getInfo();
    this.getStyles();
  },
  methods: {
    async getStyles() {
      this.$store.commit("setLoading", true);
      const res = await this.$http.get("/styles", {
        params: {
          populate: "*",
        },
      });
      this.styles = res.data.data;
      this.$store.commit("setLoading", false);
    },
    handleFileImport(toUpload) {
      this.toUpload = toUpload;
      this.$refs[toUpload].click();
    },
    onFileChanged(e) {
      console.log(e);
      this[this.toUpload + "File"] = e.target.files[0];
      if (this[this.toUpload + "File"] != null) {
        var reader = new FileReader();
        reader.readAsDataURL(this[this.toUpload + "File"]);
        reader.onload = () => {
          this[this.toUpload + "String"] = reader.result;
        };
      }
    },
    async getInfo() {
      this.$store.commit("setLoading", true);
      try {
        const res = await this.$http.get(
          "/resturants/" + this.$store.state.resturant.id,
          {
            params: {
              populate: "*",
            },
          }
        );
        this.$store.commit("setResturant", res.data.data);
        this.form = res.data.data;
        if (this.form.logo == null) {
          this.form.logo = {
            url: "",
          };
        }
        this.from = this.form.open.from.toString().substring(0, 5);
        this.to = this.form.open.to.toString().substring(0, 5);
        this.form.open.from = this.from;
        this.form.open.to = this.to;
      } catch (error) {
        console.log(error);
      }
      this.$store.commit("setLoading", false);
    },
    async saveInfo() {
      this.$store.commit("setLoading", true);
      try {
        if (this.logoFile != null) {
          let res = await this.$service.uploadFile(this.logoFile);
          this.form.logo = res.id;
        }
        if (this.backgroundFile != null) {
          let res = await this.$service.uploadFile(this.backgroundFile);
          this.form.background = res.id;
        }
        this.form.open.from = this.from + ":00.000";
        this.form.open.to = this.to + ":00.000";
        console.log(this.form);
        await this.$http.put("/resturants/" + this.$store.state.resturant.id, {
          data: this.form,
        });
        this.getInfo();
        this.$store.commit("setLoading", false);
      } catch (error) {
        console.log(error);
        this.$store.commit("setLoading", false);
      }
    },
  },
};
</script>

<style></style>
